/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
export var messages = {
    checkGoogleAccount: {
        existsAccount: (React.createElement(React.Fragment, null,
            "Google\u30A2\u30AB\u30A6\u30F3\u30C8\uFF08Gmail\u30A2\u30C9\u30EC\u30B9\uFF09\u3092",
            React.createElement("br", null),
            "\u304A\u6301\u3061\u3067\u3059\u304B\uFF1F")),
        yes: '持っている',
        no: '持っていない',
    },
    commentEnterFeedback: 'ご意見、ご感想のご記入にご協力お願い致します。',
    commentEnterFreeForm: '自由記入',
    commentEnterInputGoodPoint: 'お知り合いなどにおすすめするポイントやご満足頂けたポイントなどをご記入ください。',
    enquete: 'Customer Satisfaction Survey',
    enqueteNotFound: 'アンケートが存在しないか、読み込めませんでした。',
    googleStep: {
        googleAccountPageNextStep: (React.createElement(React.Fragment, null,
            "Google\u30A2\u30AB\u30A6\u30F3\u30C8\u30DA\u30FC\u30B8\u306B",
            React.createElement("br", null),
            "\u9032\u3093\u3060\u5F8C\u306E\u30B9\u30C6\u30C3\u30D7\u3067\u3059\u3002")),
        goToGooglePageNow: (React.createElement(React.Fragment, null,
            "\u3055\u3063\u305D\u304F",
            React.createElement("br", null),
            "Google\u30DA\u30FC\u30B8\u3078GO\uFF01")),
        step1: '「この場所での自分の体験や感想を共有しましょう」部分をタップ',
        step2: '入力欄を長押しして「ペースト」が表示されたらタップ',
        step3: 'テキストがペーストされたら「完了」をタップ',
        step4: '★（星）をタップ',
        step5: '選択肢があれば、ご感想に一番近しいタグを選んでタップ',
        step6: '画面、右上にある「投稿」をタップしたら完了です',
    },
    loading: '読込中...',
    multipleSelectAllowed: '複数選択可',
    name: 'お名前',
    namePlaceholder: '例) 田中　一郎',
    next: '次へ',
    npsOption1: 'とても紹介したくない',
    npsOption2: '紹介したくない',
    npsOption3: '紹介したい',
    npsOption4: 'とても紹介したい',
    npsText: '当店をお知り合いなどにおすすめしたいと思いますか？',
    other: 'その他',
    otherPlaceholder: 'その他の内容をご記入ください',
    plaeseAgree: 'プライバシーポリシーに同意する',
    pleaseAgreeWithUrl: function (privacyPolicyUrl) { return (React.createElement(React.Fragment, null,
        React.createElement("a", { className: "privacy-policy-text", href: privacyPolicyUrl, target: "_blank", rel: "noreferrer", style: { textDecoration: 'underline' } }, "\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC"),
        "\u306B\u540C\u610F\u3059\u308B")); },
    pleaseEnter: '入力してください。',
    pleaseEnterTextLength: function (min, length) {
        return "\u6700\u4F4E" + min + "\u6587\u5B57\u306E\u5165\u529B\u3092\u304A\u9858\u3044\u3057\u307E\u3059: \u73FE\u5728" + length + "\u6587\u5B57";
    },
    pleaseSelect: '選択してください。',
    pleaseSelectOneOrMore: '1つ以上選択してください。',
    alertForIOS: {
        qrReaderPattern: {
            title: (React.createElement(React.Fragment, null,
                "\u753B\u9762\u53F3\u4E0B\u306B\u56F3\u306E\u3088\u3046\u306A",
                React.createElement("span", { style: { borderBottom: '5px solid #ffcc58', borderRadius: '4px' } },
                    "\u30A2\u30A4\u30B3\u30F3\u304C",
                    React.createElement("span", { style: { fontSize: '2.0rem' } }, "\u3042\u308B")),
                "\u5834\u5408")),
            description: '画面右下のアイコンをタップして、Safariでアンケートの記載をお願いします。',
            caption: 'Safariアイコンの例',
        },
        safariPattern: {
            title: (React.createElement(React.Fragment, null,
                React.createElement("span", { style: { borderBottom: '5px solid #ffcc58', borderRadius: '4px' } },
                    "\u30A2\u30A4\u30B3\u30F3\u304C",
                    React.createElement("span", { style: { fontSize: '2.0rem' } }, "\u306A\u3044")),
                "\u5834\u5408")),
            description: '以下のボタンをタップしてください。',
            enqueteStartButton: 'アンケートを開始する',
        },
    },
    required: '必須',
    secondStep: {
        followOfficialSnsForDeals: (React.createElement(React.Fragment, null,
            "\u516C\u5F0FSNS\u3067\u306F\u304A\u5F97\u306A\u60C5\u5831\u3082",
            React.createElement("br", null),
            "\u914D\u4FE1\u3057\u3066\u3044\u307E\u3059\u306E\u3067\u30D5\u30A9\u30ED\u30FC\u304A\u9858\u3044\u3057\u307E\u3059\u3002")),
        googleReviewRequestMessage: (React.createElement(React.Fragment, null,
            "\u3088\u308D\u3057\u3051\u308C\u3070",
            React.createElement("br", null),
            "Google\u306E\u53E3\u30B3\u30DF\u3078\u306E\u6295\u7A3F\u306B\u3082",
            React.createElement("br", null),
            "\u3054\u5354\u529B\u304A\u9858\u3044\u3057\u307E\u3059\uFF01")),
        responseDateTime: '回答日時',
        responseId: '回答ID',
        surveyThanksMessage: (React.createElement(React.Fragment, null,
            "\u30A2\u30F3\u30B1\u30FC\u30C8",
            React.createElement("br", null),
            "\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3057\u305F\u3002")),
        useForFutureOperation: '今後の運営に活用させていただきます。',
    },
    toGoogle: {
        copyToGoogle: 'コピーしてGoogleへ',
        freeCommentCopyOnButton: 'ボタンを押すと、「自由コメント欄」の内容がコピーされます',
        missingPlaceIdOrShortName: '略称もしくはPlace IDが設定されていません。',
        moveToGoogleAndPasteReview: 'ボタンを押すとGoogleアカウントページに移動します。クチコミ欄で内容をペースト（貼り付け）してご利用ください。',
    },
    textValidationError: {
        number: '数値で入力してください',
        alphanumeric: '半角英数字で入力してください',
        email: 'メールアドレスの形式で入力してください',
    },
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as Bowser from 'bowser';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import useEnquete from '../api/use-enquete';
import { useEnquetePageviewHistoriesAdd } from '../api/use-enquete-pageview-histories-add';
import { AlertForIOS } from '../components/AlertForIOS';
import CheckGoogleAccount from '../components/CheckGoogleAccount';
import FirstStep from '../components/FirstStep';
import { Loading } from '../components/Loading';
import SecondStepGmb from '../components/SecondStepGmb';
import { MessageContext } from '../context/message-context';
import useEnqueteDesign2 from '../hooks/useEnqueteDesign2';
import Messages from '../i18n';
import { sendData } from '../utils/GoogleAnalyticsUtil';
var styles = {
    pageNotFoundWrapper: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN',\n      'Hiragino Sans', Meiryo, sans-serif;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: calc(-110px + 100vh);\n    line-height: 1.5;\n    color: black;\n  "], ["\n    font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN',\n      'Hiragino Sans', Meiryo, sans-serif;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: calc(-110px + 100vh);\n    line-height: 1.5;\n    color: black;\n  "]))),
    pageNotFoundTitle: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: 64px;\n    font-weight: bold;\n    text-align: center;\n    margin: 2rem 0px;\n  "], ["\n    font-size: 64px;\n    font-weight: bold;\n    text-align: center;\n    margin: 2rem 0px;\n  "]))),
    pageNotFoundDescription: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: 20px;\n    text-align: center;\n  "], ["\n    font-size: 20px;\n    text-align: center;\n  "]))),
};
var migationEnqueteUids = [
    'norikae.n',
    'norikae.i',
    'sb.seno',
    'sb.nishi',
    'sb.shimo',
    'akkrtbs',
    'goldengyoza',
    'U8ILEugq',
    'uxFJzAHL', // 株式会社 プラスアルファご来社アンケート
];
var defaultEnqueteForm = {
    step: 1,
    name: '',
    nps: undefined,
    comment: '',
    fieldValues: [],
    responseId: undefined,
    debug: false,
    hasGoogleAccount: true,
};
export default function EnquetePage() {
    var enqueteUid = window.location.pathname.substring(1);
    var _a = useState(defaultEnqueteForm), enqueteForm = _a[0], setEnqueteForm = _a[1];
    var step = enqueteForm.step;
    var _b = useEnquete(enqueteUid), enquete = _b.enquete, updateEnquete = _b.updateEnquete, workspace = _b.workspace, isLoadingEnquete = _b.isLoadingEnquete;
    var progressNewEnqueteDesign = useEnqueteDesign2(enquete).progressNewEnqueteDesign;
    var _c = useState(false), visibleAlertForiOS = _c[0], setVisibleAlertForiOS = _c[1];
    var _d = useState('ja'), lang = _d[0], setLang = _d[1]; // ロード時のデフォルトは日本語
    var searchParams = new URL(window.location.href).searchParams;
    var showPreviewAlert = searchParams.get('_src') === 'プレビュー';
    useEnquetePageviewHistoriesAdd(enqueteUid);
    var msg = useMemo(function () {
        return Messages.get(lang);
    }, [lang]);
    useEffect(function () {
        var modeParam = searchParams.get('mode');
        if (modeParam === 'preview') {
            setEnqueteForm(__assign(__assign({}, enqueteForm), { debug: true }));
        }
        window.addEventListener('message', function (e) {
            if (e.origin === process.env.REACT_APP_PREVIEW_MSG_SENDER_ORIGIN) {
                var setting = e.data.setting;
                // プレビューで項目の変更を表示するための処理
                if (setting) {
                    if (!(enquete === null || enquete === void 0 ? void 0 : enquete.json)) {
                        updateEnquete(__assign(__assign({}, enquete), { json: JSON.stringify(setting) }));
                    }
                    else {
                        var basedSetting = JSON.parse(enquete.json);
                        updateEnquete(__assign(__assign({}, enquete), { json: JSON.stringify(__assign(__assign({}, basedSetting), setting)) }));
                    }
                }
            }
        });
    }, []);
    useEffect(function () {
        if (enquete) {
            // FUJISAN-157 iOSのQRコードリーダーでページを読み込んだ際に、Safariで開くようにナビゲートする
            var env = Bowser.parse(window.navigator.userAgent);
            var isiOSSafari = env.os.name === 'iOS' ||
                env.platform.model === 'iPad' ||
                (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
            var isFromQRCode = window.location.hash === '#from-qr';
            var isSpecialUid = migationEnqueteUids.includes(enqueteUid) &&
                window.location.hash !== '#loaded';
            if (isiOSSafari && (isFromQRCode || isSpecialUid)) {
                // 初回読み込み
                window.location.hash = '#loaded';
                setVisibleAlertForiOS(true);
            }
            // パラメータ
            var debugParam = searchParams.get('debug');
            if (debugParam) {
                var stepParam = searchParams.get('step');
                var step_1 = stepParam ? parseInt(stepParam) : enqueteForm.step;
                var npsParam = searchParams.get('nps');
                var nps = npsParam ? parseInt(npsParam) : enqueteForm.nps;
                setEnqueteForm(__assign(__assign({}, enqueteForm), { debug: true, step: step_1, nps: nps }));
            }
            if (window.location.hash.match('step2')) {
                setEnqueteForm(__assign(__assign({}, enqueteForm), { step: 2 }));
            }
            else if (window.location.hash.match('gmb')) {
                setEnqueteForm(__assign(__assign({}, enqueteForm), { hasGoogleAccount: true, step: 3 }));
            }
            else if (window.location.hash.match('sns')) {
                setEnqueteForm(__assign(__assign({}, enqueteForm), { hasGoogleAccount: false, step: 3 }));
            }
            else if (window.location.hash.match('thanks')) {
                setEnqueteForm(__assign(__assign({}, enqueteForm), { hasGoogleAccount: false, step: 3 }));
            }
            var modeParam = searchParams.get('mode');
            if (modeParam !== 'preview') {
                sendData('start Enquete', 'アンケート開始');
            }
        }
    }, [enquete]);
    var enqueteSetting = useMemo(function () {
        if (!(enquete === null || enquete === void 0 ? void 0 : enquete.json)) {
            return undefined;
        }
        var setting = JSON.parse(enquete.json);
        if (setting.lang) {
            setLang(setting.lang);
        }
        return setting;
    }, [enquete]);
    var head = function () {
        return (_jsxs(Helmet, __assign({ defer: false }, { children: [_jsx("title", { children: (enqueteSetting === null || enqueteSetting === void 0 ? void 0 : enqueteSetting.header) || msg.enquete }, void 0),
                process.env.REACT_APP_STAGE === 'stg' && (_jsx("meta", { name: "robots", content: "noindex, nofollow" }, void 0))] }), void 0));
    };
    var previewAlert = function () {
        return (_jsx("div", __assign({ style: {
                fontSize: '16px',
                textAlign: 'center',
                width: 'calc(100% + 40px)',
                padding: '8px',
                marginLeft: '-20px',
                marginBottom: '20px',
                backgroundColor: '#ffb74e',
            } }, { children: "\u3053\u308C\u306F\u30A2\u30F3\u30B1\u30FC\u30C8\u306E\u30D7\u30EC\u30D3\u30E5\u30FC\u8868\u793A\u3067\u3059\u3002\u30A2\u30F3\u30B1\u30FC\u30C8\u306E\u8868\u793A\u56DE\u6570\u306B\u306F\u30AB\u30A6\u30F3\u30C8\u3055\u308C\u307E\u305B\u3093\u3002\u516C\u958B\u7528\u306EURL\u306F\u7BA1\u7406\u753B\u9762\u306E\u300CURL\u3092\u30B3\u30D4\u30FC\u3059\u308B\u300D\u304B\u3089\u53D6\u5F97\u3057\u3066\u304F\u3060\u3055\u3044\u3002" }), void 0));
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var languageSelector = function () {
        var handleChangeLanguage = function (e) {
            e.target.value === 'ja' ? setLang('ja') : setLang('en');
        };
        return (_jsx("div", __assign({ className: "lang-selector" }, { children: _jsxs("select", __assign({ value: lang, onChange: handleChangeLanguage }, { children: [_jsx("option", __assign({ value: "ja" }, { children: "\u65E5\u672C\u8A9E" }), void 0),
                    _jsx("option", __assign({ value: "en" }, { children: "English" }), void 0)] }), void 0) }), void 0));
    };
    var mainContent = function () {
        var _a;
        if (!enquete || !enqueteSetting) {
            if (!isLoadingEnquete) {
                return (_jsxs("div", __assign({ css: styles.pageNotFoundWrapper }, { children: [_jsx("h1", __assign({ css: styles.pageNotFoundTitle }, { children: "Not Found" }), void 0),
                        _jsx("p", __assign({ css: styles.pageNotFoundDescription }, { children: msg.enqueteNotFound }), void 0)] }), void 0));
            }
            else {
                return _jsx(Loading, { primaryColor: "#777" }, void 0);
            }
        }
        if (progressNewEnqueteDesign || isLoadingEnquete) {
            return _jsx(Loading, { primaryColor: "#777" }, void 0);
        }
        else if (visibleAlertForiOS) {
            return (_jsx(AlertForIOS, { themeColor: (_a = enqueteSetting.themeColor) !== null && _a !== void 0 ? _a : '#34b9bb', onStartEnquete: function () { return setVisibleAlertForiOS(false); } }, void 0));
        }
        else if (step === 1) {
            return (_jsx(FirstStep, { enquete: enquete, enqueteSetting: enqueteSetting, enqueteForm: enqueteForm, setEnqueteForm: setEnqueteForm, workspace: workspace }, void 0));
        }
        else if (step === 2) {
            return (_jsx(CheckGoogleAccount, { enqueteForm: enqueteForm, enqueteSetting: enqueteSetting, setEnqueteForm: setEnqueteForm }, void 0));
        }
        else if (step === 3) {
            return (_jsx(SecondStepGmb, { enquete: enquete, enqueteSetting: enqueteSetting, enqueteForm: enqueteForm }, void 0));
        }
        else {
            return null;
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(MessageContext.Provider, __assign({ value: { msg: msg } }, { children: [head(), showPreviewAlert && previewAlert(), mainContent()] }), void 0) }, void 0));
}
var templateObject_1, templateObject_2, templateObject_3;

import { messages as en } from './en';
import { messages as ja } from './ja';
var supportedLangs = ['en', 'ja'];
var Messages = /** @class */ (function () {
    function Messages() {
    }
    Messages.get = function (targetLang) {
        var langs = targetLang
            ? [targetLang]
            : window.navigator.languages.map(function (lang) { return lang.split('-')[0]; });
        var lang = langs.find(Messages.isSuppoertLang) || 'en';
        return Messages.of(lang);
    };
    Messages.isSuppoertLang = function (lang) {
        return lang !== undefined && supportedLangs.includes(lang);
    };
    Messages.of = function (language) {
        return this.map[language];
    };
    Messages.map = {
        en: en,
        ja: ja,
    };
    return Messages;
}());
export default Messages;

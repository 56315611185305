import axios from 'axios';
import { useEffect } from 'react';
import { API_END_POINT } from './api';
var useEnquetePageviewHistoriesAdd = function (enqueteId) {
    var searchParams = new URL(window.location.href).searchParams;
    var modeParam = searchParams.get('mode');
    var referrerParam = searchParams.get('_src');
    useEffect(function () {
        if (!enqueteId ||
            modeParam === 'preview' ||
            referrerParam === 'プレビュー') {
            return;
        }
        axios
            .post(API_END_POINT + "/public/enquetes/" + enqueteId + "/page-view-histories")
            .catch(function (e) { return console.error(e); });
    }, []);
};
export { useEnquetePageviewHistoriesAdd };

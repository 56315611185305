import React, { useContext } from 'react';
import { MessageContext } from '../context/message-context';
export function AlertForIOS(props) {
    var themeColor = props.themeColor, onStartEnquete = props.onStartEnquete;
    var msg = useContext(MessageContext).msg;
    return (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
            marginTop: '90px',
            color: '#777',
            fontSize: '1.5rem',
        } },
        React.createElement(Content, { themeColor: themeColor },
            React.createElement("p", { style: { fontWeight: 'bold' } }, msg.alertForIOS.qrReaderPattern.title),
            React.createElement("p", { style: {
                    lineHeight: '2.4rem',
                    marginBottom: '20px',
                } }, msg.alertForIOS.qrReaderPattern.description),
            React.createElement("div", { style: {
                    display: 'block',
                    margin: '0px auto',
                    border: '5px solid #ffcc58',
                    borderRadius: '4px',
                    textAlign: 'center',
                    width: 'fit-content',
                    padding: '10px 50px',
                } },
                React.createElement("p", { style: {
                        fontWeight: 'bold',
                        marginBottom: '10px',
                    } }, msg.alertForIOS.qrReaderPattern.caption),
                React.createElement("img", { src: "/img/icon_safari_tap.svg" }))),
        React.createElement(Content, { themeColor: themeColor },
            React.createElement("p", { style: { fontWeight: 'bold' } }, msg.alertForIOS.safariPattern.title),
            React.createElement("p", { style: {
                    lineHeight: '2.4rem',
                    marginBottom: '20px',
                } }, msg.alertForIOS.safariPattern.description),
            React.createElement("button", { style: {
                    display: 'block',
                    margin: '0px auto',
                    padding: '20px 20px',
                    borderRadius: '15px',
                    fontWeight: 'bold',
                }, onClick: onStartEnquete },
                msg.alertForIOS.safariPattern.enqueteStartButton,
                React.createElement("img", { src: "img/arrow.svg", style: { marginLeft: '10px' } })))));
}
var Content = function (_a) {
    var children = _a.children, themeColor = _a.themeColor;
    return (React.createElement("div", { style: {
            border: "5px solid " + themeColor,
            borderRadius: '3px',
            backgroundColor: 'white',
            padding: '20px 15px',
        } }, children));
};
